<!-- chenbang >>> 2023/7/12 18:27:14 -->
<template>
  <div class="swapper">
    <div class="card-style-1">
      <div class="left">
        <p class="title">GMAT模考</p>
        <p class="desc">属于自己的GMAT备考中心</p>
        <el-button class="btn" type="success" round @click="toGo">立即开始</el-button>
      </div>
      <img class="right" :src="`${domain}image/ow/pc/GMAT-PIC1.png`" alt="">
    </div>
    <div class="card-style-2">
      <img class="left" :src="`${domain}image/ow/pc/GMAT-PIC2.png`" alt="">
      <div class="right">
        <p class="desc">
          带书出门很麻烦？<br/>
          计时做题不方便？
        </p>
        <p class="title">海量分类题库在线练！</p>
      </div>
      <img class="bg-img" :src="`${domain}image/ow/pc/mock_bg1.png`" alt="">
    </div>
    <div class="card-style-3">
      <div class="left">
        <p class="desc">
          换库捉摸不定？<br/>
          机经无处找寻？
        </p>
        <p class="title">最新考情实时更新！</p>
      </div>
      <img class="right" :src="`${domain}image/ow/pc/GMAT-PIC3.png`" alt="">
    </div>
    <div class="card-style-4-anim">
      <img class="left" :src="`${domain}image/ow/pc/GMAT-PIC4.png`" alt="">
      <div class="right">
        <p class="desc">
          考试日期倒计时<br/>
          自动归纳错题本
        </p>
        <p class="title">记录我的GMAT备考轨迹！</p>
      </div>
      <img class="bg-img" :src="`${domain}image/ow/pc/mock_bg2.png`" alt="">
    </div>
  </div>
</template>

<script>
const domain = process.env.VUE_APP_IMAGE_DOMAIN

export default {
  name: 'GMAT',
  components: {},
  props: {},
  data() {
    return {
      domain
    }
  },
  methods: {
    toGo() {
      window.open('https://gmat.papaen.com/', '_blank')
    }
  }

}
</script>

<style lang="less" scoped>
@import "./style";
</style>
