<!-- chenbang >>> 2023/7/12 18:27:14 -->
<template>
  <div class="swapper">
    <div class="card-style-1">
      <div class="left">
        <p class="title">GRE模考</p>
        <p class="desc">
          自适应模考海量题库，趴趴助你备考GRE
        </p>
        <el-button class="btn" type="success" round @click="toGo">立即开始</el-button>
      </div>
      <img class="right" :src="`${domain}image/ow/pc/GRE-PIC1.png`" alt="">
    </div>
    <div class="card-style-2">
      <img class="left" :src="`${domain}image/ow/pc/GRE-PIC2.png`" alt="">
      <div class="right">
        <p class="title">1:1还原考试场景！</p>
        <p class="desc">
          真实场景模拟考试<br/>
          提前熟悉考试系统<br/>
        </p>

      </div>
      <img class="bg-img" :src="`${domain}image/ow/pc/mock_bg1.png`" alt="">
    </div>
    <div class="card-style-3">
      <div class="left">
        <p class="desc">
          带书出门很麻烦？<br/>
          计时做题不方便？<br/>
        </p>
        <p class="title">海量分类题库在线练！</p>
      </div>
      <img class="right" :src="`${domain}image/ow/pc/GRE-PIC3.png`" alt="">
    </div>
    <div class="card-style-4-anim">
      <img class="left" :src="`${domain}image/ow/pc/GRE-PIC4.png`" alt="">
      <div class="right">
        <p class="desc">
          自适应模考<br/>
          出具诊断报告<br/>
        </p>
        <p class="title">及时反馈复习情况！</p>
      </div>
      <img class="bg-img" :src="`${domain}image/ow/pc/mock_bg2.png`" alt="">
    </div>
  </div>
</template>

<script>
const domain = process.env.VUE_APP_IMAGE_DOMAIN

export default {
  name: 'GRE',
  components: {},
  props: {},
  data() {
    return {
      domain
    }
  },
  methods: {
    toGo() {
      window.open('https://gre.papaen.com', '_blank')
    }
  }

}
</script>

<style lang="less" scoped>
@import "./style";
</style>
