<!-- chenbang >>> 2023/7/12 18:27:14 -->
<template>
  <div class="swapper">
    <div class="card-style-1">
      <div class="left">
        <p class="title">托福模考</p>
        <p class="desc">
          属于自己的托福备考中心
        </p>
        <el-button class="btn" type="success" round @click="toGo">立即开始</el-button>
      </div>
      <img class="right" :src="`${domain}image/ow/pc/TOEFL-PIC1.png`" alt="">
    </div>
    <div class="card-style-2">
      <img class="left" :src="`${domain}image/ow/pc/TOEFL-PIC2.png`" alt="">
      <div class="right">
        <p class="desc">
          找不到最新的模考套题？<br/>
          考前冲刺需要全真模拟？<br/>
        </p>
        <p class="title">海量分类题库在线练！</p>
      </div>
      <img class="bg-img" :src="`${domain}image/ow/pc/mock_bg1.png`" alt="">
    </div>
    <div class="card-style-3">
      <div class="left">
        <p class="desc">
          没有范文参考？<br/>
          想要积累素材？<br/>
        </p>
        <p class="title">独家高分范文大放送！</p>
      </div>
      <img class="right" :src="`${domain}image/ow/pc/TOEFL-PIC3.png`" alt="">
    </div>
    <div class="card-style-4-anim">
      <img class="left" :src="`${domain}image/ow/pc/TOEFL-PIC4.png`" alt="">
      <div class="right">
        <p class="desc">
          考试日期倒计时<br/>
          获取模考得分<br/>
        </p>
        <p class="title">记录我的托福备考！</p>
      </div>
      <img class="bg-img" :src="`${domain}image/ow/pc/mock_bg2.png`" alt="">
    </div>
  </div>
</template>

<script>
const domain = process.env.VUE_APP_IMAGE_DOMAIN

export default {
  name: 'TOEFL',
  components: {},
  props: {},
  data() {
    return {
      domain
    }
  },
  methods: {
    toGo() {
      window.open('https://toefl.papaen.com', '_blank')
    }
  }

}
</script>

<style lang="less" scoped>
@import "./style";
</style>
