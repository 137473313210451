<!-- chenbang >>> 2023/7/12 16:45:39 -->
<template>
  <div class="mock-swapper">
    <div class="tabs-list">
      <div class="tab-card" :class="{active: active === item.text}" v-for="item of tabs" :key="item.text" @click="changeTab(item.text)">
        <div class="img-box">
          <img :src="item.img" alt="">
        </div>
        <p class="text">{{item.text}}</p>
      </div>
    </div>

    <div class="content-list-box">
      <component :is="active"></component>
    </div>
    <float-bar category="ielts"></float-bar>
    <float-ads></float-ads>

  </div>
</template>

<script>
import GMAT from './GMAT.vue';
import IELTS from './IELTS.vue';
import TOEFL from './TOEFL.vue';
import GRE from './GRE.vue';
import FloatBar from '@/components/FloatBar/FloatBar.vue'
import FloatAds from '@/components/FloatAds/FloatAds.vue'


const domain = process.env.VUE_APP_IMAGE_DOMAIN

export default {
  name: 'MockCenter',
  components: {
    GMAT,
    IELTS,
    TOEFL,
    GRE,
    FloatBar,
    FloatAds
  },
  props: {},
  data() {
    return {
      active: 'IELTS',
      tabs: [
        {
          img: `${domain}image/ow/pc/mock_tab_ielts.png`,
          text: 'IELTS'
        },
        {
          img: `${domain}image/ow/pc/mock_tab_toefl.png`,
          text: 'TOEFL'
        },
        {
          img: `${domain}image/ow/pc/mock_tab_gmat.png`,
          text: 'GMAT'
        },
        {
          img: `${domain}image/ow/pc/mock_tab_gre.png`,
          text: 'GRE'
        },

      ],


    }
  },
  methods: {
    changeTab(text) {
      this.active = text
    }
  }

}
</script>

<style lang="less" scoped>
.mock-swapper {
  min-height: 500px;

  .tabs-list {
    width: 1200px;
    display: flex;
    justify-content: space-around;
    margin: 20px auto;

    .active{
      border: 2px solid #29D087;
      color: rgba(0,0,0,0.9);
      font-weight: bold;
    }

    .tab-card {
      width: 285px;
      height: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #FFFFFF;
      border-radius: 16px;
      color: rgba(0, 0, 0, 0.60);
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
      cursor: pointer;
    }
  }

  .content-list-box {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    margin: 0 auto;
    background: #fff;
  }
}
/deep/.el-button--success{
  background-color: rgba(41, 208, 135, 1);
  border-color: rgba(41, 208, 135, 1);

  &:hover{
    background-color: rgb(42, 204, 133);
    border-color: rgb(42, 204, 133);
  }
}
</style>
