import { render, staticRenderFns } from "./mockCenter.vue?vue&type=template&id=957efd90&scoped=true"
import script from "./mockCenter.vue?vue&type=script&lang=js"
export * from "./mockCenter.vue?vue&type=script&lang=js"
import style0 from "./mockCenter.vue?vue&type=style&index=0&id=957efd90&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "957efd90",
  null
  
)

export default component.exports